import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import PortableText from '@sanity/block-content-to-react';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import Typography from '@mui/material/Typography/Typography';

import {
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  video: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  socialIcon: {
    borderRadius: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    background: theme.palette.alternate.main,
    '&:last-child': {
      marginRight: 0,
    },
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    background: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
}));

const Content = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const serializers = {
    types: {
      youtube: ({ node }) => {
        const { url } = node;
        const id = getYouTubeId(url);
        return (
          <div className={classes.video}>
            <YouTube videoId={id} />
          </div>
        );
      },
    },
  };
  const url = window.location.href;
  return (
    <div className={className} {...rest}>
      <Typography variant="h4">{data.title}</Typography>
      <br />
      <Typography variant="h5">{data.excerpt}</Typography>
      <br />
      <div className={classes.tags}>
        {data.categories.map((item, index) => (
          <Typography variant="caption" className={classes.tag} key={index}>
            {item.title}
          </Typography>
        ))}
      </div>
      <div className={classes.section}>
        <GatsbyImage
          image={data.mainImage.asset.gatsbyImageData}
          className={classes.image}
          alt={data.excerpt}
        />
      </div>

      <div className={classes.section}>
        <PortableText blocks={data._rawBody} serializers={serializers} />
      </div>

      <div className={classes.section}></div>
      <div>
        <div className="Demo__some-network">
          <TwitterShareButton
            url={url}
            title={data.excerpt}
            className="twitter-share-button"
          >
            <TwitterIcon size={64} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={url}
            title={data.excerpt}
            className="linkedin-share-button"
          >
            <LinkedinIcon size={64} round />
          </LinkedinShareButton>
          <EmailShareButton
            url={url}
            subject={data.title}
            body={data.excerpt}
            className="linkedin-share-button"
          >
            <EmailIcon size={64} round />
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};

Content.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default Content;
