import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { colors, Typography, Grid } from '@mui/material';
import LearnMoreLink from 'components/atoms/LearnMoreLink/LearnMoreLink';
import CardProduct from 'components/organisms/CardProduct/CardProduct';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    border: `1px solid ${colors.grey[200]}`,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  gridItem: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${colors.grey[200]}`,
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  cardProduct: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 0,
    '& .card-product__content': {
      padding: 0,
      paddingLeft: theme.spacing(2),
    },
    '& .card-product__media': {
      height: 90,
      width: 90,
      '& img': {
        height: 90,
        width: 90,
      },
    },
  },
  image: {
    objectFit: 'cover',
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  blogTitle: {
    fontWeight: 700,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    margin: theme.spacing(0, 1 / 2, 1 / 2, 0),
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

const SidebarArticles = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const BlogMediaContent = (props) => (
    <GatsbyImage image={props} className={classes.image} alt="About" />
  );

  const content = useStaticQuery(graphql`
    query {
      posts: allSanityPost(sort: { fields: _createdAt }, limit: 6) {
        nodes {
          title
          excerpt
          _rawSlug
          featured
          _createdAt
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 100)
            }
          }
          author {
            name
          }
        }
      }
    }
  `);

  const data = content.posts.nodes;
  const BlogContent = (props) => (
    <div className={classes.blogContent}>
      <Typography
        variant="body2"
        color="textPrimary"
        className={classes.blogTitle}
        gutterBottom
      >
        {props.title}
      </Typography>
      <Typography variant="caption" color="textPrimary">
        <i>
          {props.author} - {props.date}
        </i>
      </Typography>
      <LearnMoreLink
        title={'Learn more'}
        variant="body1"
        className={classes.button}
        href={props.readMore}
      />
    </div>
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography
        variant="h6"
        color="textPrimary"
        gutterBottom
        className={classes.sectionTitle}
      >
        Latest Articles
      </Typography>
      <Grid container spacing={0}>
        {data.map((item, index) => {
          if (item.archive) return null;
          return (
            <Grid
              item
              xs={12}
              key={index}
              data-aos="fade-up"
              className={classes.gridItem}
            >
              {item.featured !== 'archive' && (
                <CardProduct
                  className={classes.cardProduct}
                  mediaContent={
                    <BlogMediaContent
                      {...item.mainImage.asset.gatsbyImageData}
                      alt={item.title}
                    />
                  }
                  cardContent={
                    <BlogContent
                      title={item.title}
                      author={item.author.name}
                      date={item.date}
                      readMore={`/${item._rawSlug.current}`}
                    />
                  }
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

SidebarArticles.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default SidebarArticles;
