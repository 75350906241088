/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import BlogArticle from 'views/BlogArticle';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from '../src/components/SEO';

export const Head = (props) => {
  const blogData = props.pageContext.blogData;
  //console.log('blogData props', props);
  if (blogData)
    return (
      <SEO
        description={blogData.excerpt}
        title={blogData.title}
        pathname={props.location.pathname}
        cardImage={
          props.pageContext.blogData.mainImage.asset.gatsbyImageData.images
            .fallback.src
        }
      />
    );
  else return <SEO description="blog post" title="VRGeoscience Blog" />;
};

const BlogArticlePage = (props) => {
  return <WithLayout component={BlogArticle} layout={Main} data={props} />;
};

export default BlogArticlePage;
