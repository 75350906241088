import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid/Grid';
import Section from 'components/organisms/Section/Section';
import { Content, Hero, SidebarArticles } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const BlogArticle = (props) => {
  const classes = useStyles();
  const blogData = props.data.pageContext.blogData;
  console.log(blogData);
  return (
    <div className={classes.root}>
      <Hero
        cover={blogData.mainImage.asset}
        title={blogData.title}
        subtitle={blogData.excerpt}
        author={blogData.author}
        date={blogData._createdAt}
      />
      <Section>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Content data={blogData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SidebarArticles data={props.data.pageContext.sidebarData} />
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

export default BlogArticle;
